import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { IsMobileContext, LanguageContext } from '../common/Context'
import { AnimationService } from '../common/AnimationService'
import { BlogCategory } from '../common/typings/enums'
import _get from 'lodash/get'

//components
import Layout from '../components/Layout'
import BlogArticlesSection from '../components/BlogArticlesSection'
import FloatingButton from '../components/FloatingButton'
import Footer from '../components/Footer'
import ImageVideoSection from '../components/ImageVideoSection'
import ClientsSection from '../components/ClientsSection'
import Navbar from '../components/Navbar'
import Tags from '../components/Tags'
import Person from '../components/Person'

//models, hooks
import { useSeoData } from '../components/SeoComponent/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'
import { useBlogArticlesSectionData, BlogArticlesSectionElement } from '../components/BlogArticlesSection/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { Employee } from '../components/Person/model'

//other
import withSSR from '../hoc/withSSR'
import './blog-author.scss'

const BlogAuthorPage: React.FC<PageProps> = ({ location: { pathname }, data }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData),
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const authorId = _get(data, 'kontentItemEmployeeElement.id', '')
  const pageCodename = 'blog_author_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const blogArticlesSectionData = useBlogArticlesSectionData(language)
  const blogCategoriesSet = new Set<string>()
  const allAuthorArticles = blogArticlesSectionData.blogArticles.filter(article => article.authorId === authorId)
  allAuthorArticles.forEach(article => blogCategoriesSet.add(article.category))
  const blogCategories = [BlogCategory.ALL, ...[...blogCategoriesSet].sort()]

  const pageData = {
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
    leadingTitle: _get(data, 'kontentItemBlogAuthorSection.elements.title.value', ''),
    authorData: new Employee(_get(data, 'kontentItemEmployeeElement', {})),
    clientsSectionData: useClientsSectionData(language, pageCodename),
    imageVideoSectionData: useImageVideoSectionData(language, pageCodename),
    allAuthorArticles,
    blogCategories,
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <BlogAuthorPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface BlogAuthorPageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    leadingTitle: string
    authorData: Employee
    allAuthorArticles: BlogArticlesSectionElement[]
    blogCategories: string[]
    clientsSectionData: ClientsSectionClass
    imageVideoSectionData: ImageVideoSectionClass
  }
}

const BlogAuthorPageTemplate: React.FC<BlogAuthorPageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    leadingTitle,
    authorData,
    allAuthorArticles,
    blogCategories,
    clientsSectionData,
    imageVideoSectionData
  }
}) => {
  const [selectedBlogCategory, setSelectedBlogCategory] = React.useState<string>(BlogCategory.ALL)

  const blogsFilteredByCategory = selectedBlogCategory === BlogCategory.ALL
    ? allAuthorArticles
    : allAuthorArticles.filter(article => article.category === selectedBlogCategory)
  const filteredAllAuthorArticles = { blogArticles: blogsFilteredByCategory }

  const tagsRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (tagsRef.current) {
      if (!AnimationService.isInView(tagsRef.current)) {
        AnimationService.slideUp(tagsRef.current)
      }
    }
  }, [])

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} />
      </header>
      <main className="AuthorPage">
        <section className="LeadingSection GlobSectPadSpacing mx-mobile-xs lg:mx-m">
          <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
          <div className="grid grid-cols-12 gap-x-m">
            <div className='col-span-12 lg:col-span-6'>
              <h1 className='FontXL mb-mobile-s lg:mb-s'>{leadingTitle}</h1>
            </div>
            <div className='col-span-12 lg:col-span-6'>
              <Person {...authorData} isArticleAuthor style={{ margin: 0 }} />
            </div>
          </div>
        </section>
        <section ref={tagsRef} className="TagsSection GlobSectPadSpacing mx-mobile-xs lg:mx-m">
          <Tags
            tags={blogCategories}
            initiallyActive={BlogCategory.ALL}
            onChange={blogCategory => setSelectedBlogCategory(blogCategory)}
          />
        </section>
        <BlogArticlesSection {...filteredAllAuthorArticles} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const BlogAuthorPageTemplateWithSSR = withSSR<BlogAuthorPageProps>(BlogAuthorPageTemplate)

export default BlogAuthorPage

export const query = graphql`
  query($language: String!, $authorId: String) {
    locales: allLocale(filter: {ns: {in: ["common","blog"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    kontentItemBlogAuthorSection {
      elements {
        title {
          value
        }
      }
    }
    kontentItemEmployeeElement(
      id: {eq: $authorId}
      preferred_language: {eq: $language}
    ) {
      elements {
        name {
          value
        }
        position {
          value
        }
        description {
          value
        }
        linkedin {
          value
        }
        photo_or_video {
          value {
            url
            description
            height
            width
            name
            size
            type
          }
        }
      }
      id
    }
  }
`
